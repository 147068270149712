import { PUBLIC_SENTRY_DSN } from '$env/static/public'
import { init } from '@jill64/sentry-sveltekit-cloudflare/client'

const onError = init(PUBLIC_SENTRY_DSN, {
  sentryOptions: {
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
  },
})

export const handleError = onError((e, sentryEventId) => {
  return {
    status: e.status,
    message: e.message,
    errorId: sentryEventId,
  }
})
